/*
  page.jsx
*/
import React from 'react'

import Header from './header.jsx'
import Footer from './footer.jsx'
import Container from './container.jsx'
import Paginator from './paginator.jsx'
import { State } from 'react-router'

import data from '../data.json'

var Page = React.createClass ({

  POST_NUM_PAR_A_PAGE : 4,

  mixins: [ State ],

  getInitialState() {
    return this.getStateCurrent()
  },

  getStateCurrent() {
    return {id : this.getCurrentId().id}
  },

  getCurrentId() {
    var id = 1;
    if (this.props.params.id) {
      id = +this.props.params.id;
    };

    var max = Math.floor(data.length / this.POST_NUM_PAR_A_PAGE) + 1;
  
    return {id: id, max: max};
  },

  getCurrentData() { // generate datas for each page..
    var index = this.POST_NUM_PAR_A_PAGE * this.getCurrentId().id - 1;
    return data.slice(index, index + this.POST_NUM_PAR_A_PAGE);
  },

  render() {
    var currentData = this.getCurrentData();
    var currentId = this.getCurrentId();

    return (
      <div>
        <Header />
        <Container data={currentData}/>
        <Paginator currentPage={currentId.id} max={currentId.max}/>
        <Footer />
      </div>
    )
  }
})

module.exports = Page;
