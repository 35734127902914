/*
  index.jsx
*/
import React from 'react'
import Router, { Route, IndexRoute } from 'react-router'

import Page from './page.jsx'

class Index extends React.Component {
  render() {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

var routes = (
  <Route path= '/' component={Index}>
    <IndexRoute name='page' component={Page}/>
    <Route name='page' path='page/:id' component={Page} />
  </Route>
)

React.render(
  <Router routes={routes} />,
 document.getElementById('content')
);
