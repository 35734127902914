/*
  footer.jsx
*/
import React from 'react'

var Footer = React.createClass({

  render() {
    var nextClick = this.nextPage;
    return (
      <div className="footer">
        <hr></hr>
        <p>
          SythDef-Graphs : 2015
        </p>
      </div>
    )
  }
})

module.exports = Footer;