/*
  header.jsx
*/
import React from 'react'
import { Link } from 'react-router'

export default class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="header">
        <h1>
          <Link to="/#/">
            SynthDef-Graphs
          </Link>
        </h1>
      </div>
    )
  }
}