/*
  paginator.jsx
*/
import React from 'react'
import { State, Link, History } from 'react-router'
import { createLocation } from 'history'

var Paginator = React.createClass({
  mixins: [ State, History ],
  propTypes: {
    params: React.PropTypes.object,
    max: React.PropTypes.number.isRequired,
    maxVisible: React.PropTypes.number,
  },
  componentDidUpdate: function(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
        //console.log('staet change..')
    }
  },
  getDefaultProps: function() {
    return {
      maxVisible: 5
    };
  },
  getInitialState: function() {
    var id = 1;
    if (this.props.params) {
      id = this.props.params.id;
    }
    return {
      currentPage: id,
      items: []
    };
  },
  onClicked(newPage) {
    this.setState({currentPage: newPage});
  },
  render: function() {
    var className = this.props.className || '',
      p = this.props,
      s = this.state,
      skip = 0;

    if (s.currentPage > p.maxVisible - 1 && s.currentPage < p.max) {
      skip = s.currentPage - p.maxVisible + 1;
    } else if (s.currentPage === p.max) {
      skip = s.currentPage - p.maxVisible;
    }

    var iterator = Array.apply(null, Array(p.maxVisible)).map(function(v, i) {
      return skip + i + 1;
    });

    var previousPage = 1; 
    if (s.currentPage > 1) {
      previousPage = s.currentPage - 1;
    };
    var nextPage = 1;
    if (s.currentPage < p.max) {
      nextPage = s.currentPage + 1
    }

    return (
      <nav className='paginator'>
        <ul className={'pagination ' + className}>
          <li className={s.currentPage === 1 ? 'disabled' : ''}>
            
              <Link to={'page/' + previousPage} onClick={this.onClicked.bind(this, previousPage)}>   
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Prev</span>
              </Link>
            
          </li>
          {iterator.map(function(page) {
            return (
              <li key={page}
                className={s.currentPage === page ? 'active' : ''}>
                <Link to={'page/' + page} onClick={this.onClicked.bind(this, page)}>
                  {page}
                </Link>
              </li>
            );
          }, this)}
          <li className={s.currentPage === p.max ? 'disabled' : ''}>
            <Link to={'page/' + nextPage} onClick={this.onClicked.bind(this, nextPage)}>
              <span aria-hidden="true">&raquo;</span>
              <span className="sr-only">Next</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
});

module.exports = Paginator;
