/*
  container.jsx
*/
import React from 'react'
import Post from './post.jsx'

var Container = React.createClass ({

  render() {
    var rows = [];
    var data = this.props.data;

    data.map( (item) => {
      var key;
      var title = key = item.title + '.scsyndef';
      var image = './images/' + item.image;
      var date = item.date;
      rows.push(<Post title={title} image={image} date={date} key={key} />)
    })

    return (
      <div className="post-container">
        {rows}
      </div>
    )
  }
})

module.exports = Container;
