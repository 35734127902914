/*
  post.jsx
*/
import React from 'react'

var Post = React.createClass({

  render() {
    return (
      <div className="post">
        <div className="date">
          <p>
            {this.props.date}
          </p>
        </div>
          <a href={this.props.image} target="blank">
            <img src={this.props.image}>
            </img>            
          </a>
        <div className="title">
          <p>
            {this.props.title}
          </p>
        </div>
      </div>
    )
  }
})

module.exports = Post;
